.FIE_image-tool-button {
    display: none !important;
}
.FIE_image-tool-button {
    display: none !important;
}
.FIE_ellipse-tool-button {
    display: none !important;
}
.FIE_polygon-tool-button {
    display: none !important;
}
.FIE_line-tool-button {
    display: none !important;
}
/*
.FIE_arrow-tool-button {
    display: none !important;
}
*/

.FIE_rect-tool-button {
    display: none !important;
}





